import React from "react"

class Wrapper extends React.Component {
    handleResize = event => {
      if (window.innerWidth > 750) {
        this.props.resized()
      }
    }
  
    componentDidMount() {
      window.addEventListener("resize", this.handleResize)
    }
  
    componentDidUnmount() {
      window.removeEventListener("resize", this.handleResize)
    }
  
    render() {
      return <div>{this.props.children}</div>
    }
  }

  export default Wrapper