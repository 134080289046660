import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import footerStyles from "./footer.module.css"

const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: 1020px;
  display: grid;
  justify-content: center;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  border-top: 1px solid #ffffff25;

  @media screen and (max-width: 680px) {
    padding: 25px 0px;
    grid-template-columns: auto;
    justify-items: center;
  }
  @media screen and (min-width: 680px) {
    padding: 15px 0px;
    grid-template-columns: auto auto auto auto auto;
  }
`

export default ({ data }) => {
  return (
    <FooterContainer>
      <Link
        class={footerStyles.footeritem}
        activeClassName={footerStyles.footeritemactive}
        to="podmienkypouzivania"
      >
        Podmienky používania
      </Link>
      <a
        class={footerStyles.footeritem}
        href="http://www.facebook.com/objav"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </a>
      <span class={footerStyles.footeritem}>
        © 2019 Objav.sk - Všetky práva vyhradené
      </span>
    </FooterContainer>
  )
}
