import React from "react"

const Global = React.createContext({});

class Store extends React.Component {
  
  static Consumer = Global.Consumer;
  
  state = {
    isHambOpen: false, 
  };
  changeIsHambOpen = (isHambOpen) => this.setState({ isHambOpen });

  render() {
    const { isHambOpen } = this.state;
    const { changeIsHambOpen } = this;

    return (
      <Global.Provider value={{
        isHambOpen,
        changeIsHambOpen
      }}>
        {this.props.children}
      </Global.Provider>
    )
  }
}

export default Store;