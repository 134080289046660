import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import HambmenuItem from "../components/hambmenuitem"
import * as MenuIcons from "../components/menuicons"

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const HambMenuBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  top: 0px;
  margin: 0px;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 0;
  opacity: 0;

  @media screen and (max-width: 775px) {
    display: block;
  }
  @media screen and (min-width: 775px) {
    display: none;
  }
  transition: opacity 0.2s ease;
  ${({ closed }) =>
    closed &&
    `
    z-index: 100;
    opacity: 1;
    `}
`

const HambMenuContainer = styled.div`
  position: absolute;
  right: -160px;
  z-index: 200;
  width: 220px;
  padding: 60px 0px 0px 30px;
  background-color: rgba(64, 79, 96, 1);
  transition: right 0.4s ease;
  ${({ closed }) =>
    closed &&
    `
    right: 0px;
    `}
`

const HambMenu = props => (
  <StaticQuery
    query={graphql`
      {
        allDataJson(sort: { fields: sitemeta___slug }) {
          nodes {
            sitemeta {
              menuName
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <HambMenuBg closed={props.closed}>
        <HambMenuContainer closed={props.closed}>
          {data.allDataJson.nodes.map((item, index) => (
            <HambmenuItem
              key={index}
              to={item.sitemeta.slug !== "index" ? item.sitemeta.slug : "/"}
              linkText={item.sitemeta.menuName}
            >
              {MenuIcons[
                "Menuicon" + capitalizeFirstLetter(item.sitemeta.slug)
              ]()}
            </HambmenuItem>
          ))}
        </HambMenuContainer>
      </HambMenuBg>
    )}
  ></StaticQuery>
)

export default HambMenu
