import React from "react"
import { Link } from "gatsby"
import Store from "../components/store"
import styled from "styled-components"
import HeaderMobileStyles from "./headermobile.module.css"
import HambMenu from "../components/hambmenu"
import Cameleon from "../components/cameleonicon"

const HeaderMobileContainer = styled.div`
  width: 100%;
  min-width: 320px;
  height: 60px;
  position: fixed;
  top: 0px;
  z-index: 100;
  padding: 0px;
  @media screen and (min-width: 775px) {
    display: none;
  }
  ${({ bgColor }) =>
  `
  background: ${bgColor};
  `}
`

const ContentContainer = styled.div`
  position: absolute;
  top 0;
  width: 100%;
  min-width: 320px;
  height: 48px;
  border-bottom: 1px solid rgb(0,0,0,0.2);
  background-color: rgba(0,0,0,0.2);
  @media screen and (max-width: 775px) {
    display: flex;
    align-items: center;
  }

}
`

const TargetBurger = styled.a`
  position: fixed;
  z-index: 200;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 48px;
  display: block;
  transition: all 1s ease;
  &:hover {
    cursor: pointer;
    .opacity(0.45);
  }
  @media screen and (min-width: 775px) {
    display: none;
  }
`

const UlBuns = styled.ul`
  display: block;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin: 14px auto;
  list-style: none;
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1),
    color 1s cubic-bezier(0.23, 1, 0.32, 1);
  transform: translateZ(0);
  color: #fff;
`

const LiBun = styled.li`
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -0.75px;
  transform: translateY(-3.75px) translateZ(0);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1s cubic-bezier(0.23, 1, 0.32, 1);
  &:last-child {
    transform: translateY(3.75px) translateZ(0);
  }
  ${({ closed }) =>
    closed &&
    `
    transform: rotate(45deg) translateZ(0);
    &:last-child{
        transform: rotate(-45deg) translateZ(0);
    }
    `}
`

const TitleContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

const Title = styled.p`
  margin: 0px 0px 3px 0px;
  font-weight: 900;
  font-size: 17px;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
`

const SubTitle = styled.p`
  margin: 0px;
  font-weight: 400;
  font-size: 10px;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
`


const HeaderMobile = props => (
  <Store.Consumer>
    {({ changeIsHambOpen, isHambOpen }) => (
      <>
        <HeaderMobileContainer closed={props.closed} bgColor={props.bgColor}>
          <ContentContainer>
            <>
              <Cameleon styleName={HeaderMobileStyles.cameleonicon} />
              <TitleContainer>
                <Link to="/">
                  <Title>Objav.sk</Title>
                  <SubTitle>{props.menuName}</SubTitle>
                </Link>
              </TitleContainer>
            </>
          </ContentContainer>
        </HeaderMobileContainer>
        <HambMenu closed={isHambOpen} />
        <TargetBurger onClick={() => changeIsHambOpen(!isHambOpen)}>
          <UlBuns>
            <LiBun closed={isHambOpen} />
            <LiBun closed={isHambOpen} />
          </UlBuns>
        </TargetBurger>
      </>
    )}
  </Store.Consumer>
)

export default HeaderMobile