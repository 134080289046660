import React from "react"
import { Link } from "gatsby"
import hambmenuitemStyles from "./hambmenuitem.module.css"

const isActive = ({
  isCurrent
}) => {
  return isCurrent
    ? { className: hambmenuitemStyles.menutext + " " + hambmenuitemStyles.menuactive }
    : null
}

export default (props) => (
  <div className={hambmenuitemStyles.menuitem} >
    {props.children}
    <Link getProps={isActive} className={hambmenuitemStyles.menutext} to={props.to + "/"}>{props.linkText}</Link>
  </div>
)     