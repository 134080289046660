import React from "react"
import { Link } from "gatsby"
import mainmenuitemStyles from "./mainmenuitem.module.css"

const isActive = ({
  isCurrent
}) => {
  return isCurrent
    ? { className: mainmenuitemStyles.menutext + " " + mainmenuitemStyles.menuactive }
    : null
}


export default (props) => (
  <div className={mainmenuitemStyles.menuitem} >
    {props.children}
    <Link getProps={isActive} className={mainmenuitemStyles.menutext} to={props.to + "/"}>{props.linkText}</Link>

  </div>
)