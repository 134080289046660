import React from "react"
import { StaticQuery, graphql } from "gatsby"
import MainmenuItem from "../components/mainmenuitem"
import * as MenuIcons from "../components/menuicons"

function capitalizeFirstLetter(string) {return string.charAt(0).toUpperCase() + string.slice(1);}

const MainMenu = () => (
  <StaticQuery
    query={graphql`
    {
      allDataJson(sort: {fields: sitemeta___slug}) {
        nodes {
          sitemeta {
            menuName
            slug
          }
        }
      }
    }
  `}
    render={data => 
      data.allDataJson.nodes.map((item, index) => (
      <MainmenuItem key={index} to={(item.sitemeta.slug !== "index") ? item.sitemeta.slug : "/"} linkText={item.sitemeta.menuName}>
        {
          MenuIcons["Menuicon" + capitalizeFirstLetter(item.sitemeta.slug)]()
        }
      </MainmenuItem>
      ))
  } 
    
  ></StaticQuery>
)

export default MainMenu