import React from "react"

const MenuiconAuto = props => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g
    fill="none"
    stroke="#fff"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1"
  >
    <path d="M21.75 17a1.5 1.5 0 001.5-1.5v-2.251c0-.48-.191-.94-.53-1.279L20.25 9.5H3.75l-2.47 2.47c-.339.339-.53.799-.53 1.279V15.5a1.5 1.5 0 001.5 1.5h19.5z" />
    <path d="M.912 12.5h3.717c.398 0 .779.158 1.06.439L6.75 14M23.088 12.5h-3.717c-.398 0-.779.158-1.06.439L17.25 14M3.75 9.5l1.7-4.54A2.249 2.249 0 017.559 3.5h8.882c.938 0 1.777.582 2.106 1.46l1.7 4.54H3.75zM3.75 9.5L2.25 8H.75M20.25 9.5l1.5-1.5h1.5M2.25 17v1.5a1.5 1.5 0 003 0V17h-3zM21.75 17v1.5a1.5 1.5 0 01-3 0V17h3z" />
  </g>
</svg>
)

const MenuiconCestovanie = props => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <path d="M3.75 6.749h16.5a3 3 0 013 3v9a3 3 0 01-3 3H3.75a3 3 0 01-3-3v-9a3 3 0 013-3zM6.75 6.749v15M17.25 6.749v15" />
        <path d="M15.75 6.749v-3a1.5 1.5 0 00-1.5-1.5h-4.5a1.5 1.5 0 00-1.5 1.5v3" />
      </g>
    </svg>
)

const MenuiconChat = props => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g
    fill="none"
    stroke="#fff"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1"
  >
    <path d="M21.75 18.75h-10.5l-6 4.5v-4.5h-3a1.5 1.5 0 01-1.5-1.5v-15a1.5 1.5 0 011.5-1.5h19.5a1.5 1.5 0 011.5 1.5v15a1.5 1.5 0 01-1.5 1.5zM7.5 9.375h0" />
    <path d="M7.5 9.375a.375.375 0 100 .75.375.375 0 000-.75M12 9.375h0M12 9.375a.375.375 0 100 .75.375.375 0 000-.75M16.5 9.375h0M16.5 9.375a.375.375 0 100 .75.375.375 0 000-.75" />
  </g>
</svg>
)

const MenuiconEshop = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>
    <g
      stroke="#fff"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8.982 11.517a.375.375 0 110 .75.375.375 0 010-.75M14.982 11.517a.375.375 0 110 .75.375.375 0 010-.75M16.188 16.5a5.064 5.064 0 01-8.42 0" />
      <path d="M19.049 6.75H4.936A1.4 1.4 0 003.5 7.907l-2 13.915a1.356 1.356 0 001.437 1.428h18.111a1.357 1.357 0 001.436-1.428l-2-13.915a1.4 1.4 0 00-1.435-1.157zM15.662 3.807A3.765 3.765 0 0011.976.75h0A3.762 3.762 0 008.3 3.788" />
    </g>
  </svg>
)

const MenuiconFestivaly = props => (
  <svg 
    viewBox="0 0 24 24" 
    height="24" 
    width="24" 
    {...props}
    >
    <g
    stroke="#fff"
    fill="none"
    strokeWidth={1}
    strokeLinecap="round"
    strokeLinejoin="round"
    >
      <path d="M17.439 9V5.25a1.5 1.5 0 011.5-1.5h0a1.5 1.5 0 011.5 1.5v10.5a5.17 5.17 0 01-1.5 3.75v3.75M11.439 9V2.25a1.5 1.5 0 00-1.5-1.5h0a1.5 1.5 0 00-1.5 1.5V13.5L6 11.548a1.54 1.54 0 00-2.242.348h0a1.54 1.54 0 000 1.707L8.75 19.5v3.75M14.439 9a1.5 1.5 0 00-1.5-1.5h0a1.5 1.5 0 00-1.5 1.5v3a1.5 1.5 0 003 0V9zM17.439 9a1.5 1.5 0 00-1.5-1.5h0a1.5 1.5 0 00-1.5 1.5v3a1.5 1.5 0 003 0V9z" />
    </g>
    </svg>
)
  


const MenuiconHoroskop = props => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <path d="M5.628 19.347C1.467 15.928.867 9.783 4.286 5.622S13.85.861 18.011 4.28c.445.365.856.77 1.229 1.208M21.567 11.586c.125 5.384-4.137 9.85-9.521 9.975a9.97 9.97 0 01-.599-.004" />
        <path d="M3.147 16.273c-1.934 3.015-2.684 5.443-1.742 6.386 1.631 1.631 7.712-1.807 13.583-7.677S24.3 3.03 22.665 1.4c-.939-.939-3.379-.171-6.377 1.745" />
        <path d="M12.945 5.814a1.874 1.874 0 11.001 3.749 1.874 1.874 0 01-.001-3.749zM2.783 8.147a3.751 3.751 0 11.788 7.417 4.239 4.239 0 01-.8-.107" />
      </g>
    </svg>
)

const MenuiconIndex = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>
    <g
      stroke="#fff"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18.75 16c-3.75-.75-4.5 4.5-.75 4.5s5.25-3 5.25-6.75A8.3 8.3 0 0015 5.5C9.75 5.5 8.25 7 8.25 7a2.664 2.664 0 00-1.5-2.25c-4.5.75-6 5.25-6 5.25s.75 3.75 6.75 3.75L3.75 17.5h4.5S12 16 12 13.75a2.2 2.2 0 00-2.25-2.25M5.625 8.5c.207 0 .375.168.375.375M5.25 8.875c0-.207.168-.375.375-.375M5.625 9.25a.375.375 0 01-.375-.375M6 8.875a.375.375 0 01-.375.375M.75 17.5H13.5M12 13.75h3.75" />
    </g>
  </svg>
)

const MenuiconInzercia = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>
    <g
      stroke="#fff"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.29 14.34l-5.16.986M14.416 8.478l-3.468 1.146a1.15 1.15 0 01-1.405-.611 1.165 1.165 0 01.529-1.53l3.359-1.689a2.342 2.342 0 011.861-.108l4.973 1.819" />
      <path d="M3.685 14.348H6.13l3.512 3.936A1.343 1.343 0 0012 17.408v-.615l.175.07a1.656 1.656 0 002.271-1.537h.489a1.468 1.468 0 001.174-2.348L12.97 8.956M10.583 7.225l-.358-.295a2.098 2.098 0 00-2.024-.258L3.75 8.452" />
      <path d="M.75 6.75h2a.96.96 0 011 .916v6.417a.962.962 0 01-1 .917h-2M23.25 15h-2a.962.962 0 01-1-.917V7.666a.96.96 0 011-.916h2M12 16.793l-.978-.978M14.446 15.326l-1.468-1.468" />
    </g>
  </svg>
)

const MenuiconIt = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>
    <g
      stroke="#fff"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2.247 2.25h19.5a1.5 1.5 0 011.5 1.5v12a1.5 1.5 0 01-1.5 1.5h-19.5a1.5 1.5 0 01-1.5-1.5v-12a1.5 1.5 0 011.5-1.5zM15.747 21.75h-7.5l.75-4.5h6l.75 4.5zM5.997 21.75h12" />
    </g>
  </svg>
)

const MenuiconKupele = props => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g
    fill="none"
    stroke="#fff"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1"
  >
    <path d="M3.751 3.75l-3 12.75 3.508 1.528" />
    <path d="M15.751.75c2.5 0 4 1 4.5 3l-3 7.5a25.531 25.531 0 013 11.25 37.463 37.463 0 01-8.25.75 37.463 37.463 0 01-8.25-.75 25.531 25.531 0 013-11.25l-3-7.5c.5-2 2-3 4.5-3h7.5z" />
    <path d="M20.251 3.75l3 12.75-3.508 1.528M6.751 11.25h10.5M14.251 23.201V11.25l-6-10.5M15.751.75l-3.75 6.562M9.751 11.25v4.5" />
  </g>
</svg>
)

const MenuiconPraca = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>
    <g
      stroke="#fff"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 14.25a1.5 1.5 0 01-1.5 1.5h-9a1.5 1.5 0 010-3h9a1.5 1.5 0 011.5 1.5zM12 15.75v7.5M5.463 23.25A6.658 6.658 0 0112 18.75a6.66 6.66 0 016.537 4.5" />
      <path d="M3 9.75v3a3 3 0 003 3h1.5M1.5 9.75h3M21 9.75v3a3 3 0 01-3 3h-1.5M22.5 9.75h-3M16.5 5.25a4.5 4.5 0 00-9 0v7.5h9v-7.5z" />
    </g>
  </svg>
)

const MenuiconPremuzov = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>
    <g
      stroke="#fff"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 .75a3 3 0 110 6 3 3 0 010-6zM17.25 13.5a5.25 5.25 0 00-10.5 0v2.25H9l.75 7.5h4.5l.75-7.5h2.25V13.5zM12 8.25v6" />
    </g>
  </svg>
)

const MenuiconPrezeny = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>

    <g
      stroke="#fff"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7.988 11.125l5.798 5.798a.5.5 0 010 .707l-5.081 5.081a.999.999 0 01-1.414 0L2.2 17.619a.999.999 0 010-1.414l5.081-5.081a.5.5 0 01.707.001z" />
      <path d="M13.045 7.513l4.35 4.35a.5.5 0 010 .707l-3.981 3.981h0l-5.057-5.057h0l3.981-3.981a.5.5 0 01.707 0z" />
      <path d="M20.7 7.82l-3.675 3.674-3.611-3.612 6.714-6.714a.512.512 0 01.872.361V7.1c-.001.27-.109.529-.3.72z" />
    </g>
  </svg>
)

const MenuiconReality = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>
    <g
      stroke="#fff"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.75 13.939v8.25h6v-6a1.5 1.5 0 011.5-1.5h1.5a1.5 1.5 0 011.5 1.5v6h6v-8.25M.75 12.439L10.939 2.25a1.5 1.5 0 012.121-.001l.001.001L23.25 12.439" />
      <path d="M16.5 5.689v-1.5h3.75v5.25M1.5 22.189h21" />
    </g>
  </svg>
)

const MenuiconRecepty = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>
    <g
      stroke="#fff"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.75 12.75h16.5v8a1 1 0 01-1 1H4.75a1 1 0 01-1-1v-8h0z" />
      <path d="M3.75 13.447a6 6 0 013-11.2c4.5 0 5.25 4.5 9 4.5" />
      <path d="M20.25 13.447a6 6 0 00-3-11.2c-2.54 0-3.885 1.434-5.25 2.683M8.25 17.25v1.5M12 17.25v1.5M15.75 17.25v1.5" />
    </g>
  </svg>
)

const MenuiconSport = props => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g
    fill="none"
    stroke="#fff"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1"
  >
    <path d="M12 .75C5.787.75.75 5.787.75 12S5.787 23.25 12 23.25 23.25 18.213 23.25 12 18.213.75 12 .75z" />
    <path d="M8.895 16.754l-1.91-5.9L12 7.208l5.015 3.646-1.91 5.9h-6.21zM16.301 1.601L12 4.5 7.699 1.601M14.599 22.948l2.139-4.366 4.884-.75M23.214 12.909l-3.427-3.413.735-4.839M12 4.5v2.708M17.015 10.854l2.772-1.358M.786 12.909l3.427-3.413-.735-4.839M6.985 10.854L4.213 9.496M15.105 16.754l1.633 1.828M9.401 22.948l-2.139-4.366-4.884-.75M8.895 16.754l-1.633 1.828" />
  </g>
</svg>
)

const MenuiconVzdelavanie = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>
    <g
      stroke="#fff"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.4 5.144L20.25 3 12.4.859a1.512 1.512 0 00-.79 0L3.75 3l7.855 2.143c.26.071.535.072.795.001zM17.25 7.154V3.82M7.5 4.024v2.823M3.75 3.001v3M8.25 6.751a3 3 0 110 6 3 3 0 010-6zM8.25 9.376a.375.375 0 00-.375.375M8.625 9.751a.375.375 0 00-.375-.375M8.25 10.126a.375.375 0 00.375-.375M7.875 9.751c0 .207.168.375.375.375M15.75 6.751a3 3 0 110 6 3 3 0 010-6zM15.75 9.376a.375.375 0 00-.375.375M16.125 9.751a.375.375 0 00-.375-.375M15.75 10.126a.375.375 0 00.375-.375M15.375 9.751c0 .207.168.375.375.375" />
      <path d="M9.484 12.486L12 15.001l2.516-2.515" />
      <path d="M18 15v2.337a6 6 0 11-12 0V15" />
      <path d="M18.225 11.447a3.132 3.132 0 013.525 3.076v2.065a5.216 5.216 0 01-5.732 5.142M5.657 11.262A3.149 3.149 0 002.25 14.4v2.1a5.251 5.251 0 005.732 5.23" />
    </g>
  </svg>
)

const MenuiconZabava = props => (
  <svg viewBox="0 0 24 24" height="24" width="24" {...props}>
  <g
    stroke="#fff"
    fill="none"
    strokeWidth={1}
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3.492.833A1.5 1.5 0 001.5 2.25v8.643a11.153 11.153 0 001.008 4.9c1.637 3.359 4.817 5.8 8.965 7.362.34.128.715.128 1.055 0 4.148-1.558 7.327-4 8.964-7.362a11.153 11.153 0 001.008-4.9V2.25A1.5 1.5 0 0020.508.833a25.235 25.235 0 01-17.016 0z" />
    <path d="M7.5 13.125A4.325 4.325 0 0012 17.25a4.325 4.325 0 004.5-4.125M9 8.625a1.874 1.874 0 10-3.75 0M18.75 8.625a1.874 1.874 0 10-3.75 0" />
  </g>
</svg>
)

export {
  MenuiconAuto,
  MenuiconCestovanie,
  MenuiconChat,
  MenuiconEshop,
  MenuiconFestivaly,
  MenuiconHoroskop,
  MenuiconIndex,
  MenuiconInzercia,
  MenuiconIt,
  MenuiconKupele,
  MenuiconPraca,
  MenuiconPremuzov,
  MenuiconPrezeny,
  MenuiconReality,
  MenuiconRecepty,
  MenuiconSport,
  MenuiconVzdelavanie,
  MenuiconZabava,
}
