import React from "react"
import { Link } from "gatsby"
import logo from "../images/objavsk_logo.webp"
import MainMenu from "../components/mainmenu"

import SidebarStyles from "./sidebar.module.css"

export default () => (
    <div className={SidebarStyles.sidebar}>
        <Link to="/">
            <img className={SidebarStyles.logo} src={logo} alt="objav.sk logo" />
        </Link>
        <MainMenu />
    </div>
)